// icons.scss

.tiktok {
    display: inline-block;
    font-size: 0 !important;
    width: 24px;
    height: 24px;

    &::before {
      display: inline-block;
      content: "";
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23212529' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
}
.orange-box .tiktok::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23e67b1b' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
    width: 40px;
    height: 40px;
}
.dashed-box .tiktok {
    width: 50px;
    height: 50px;

    &::before {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23999999' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
        width: 50px;
        height: 50px;
    }
}
.influencer-row .tiktok {
    width: 18px;
    height: 18px;

    &::before {
        width: 18px;
        height: 18px;
    }
}
.influencer-row .tiktok::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23999999' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
}
.social-wrapper .tiktok::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23fff' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
}
.centerbar .influencer-row .tiktok, .message-box .message-title .tiktok {
    width: 14px;
    height: 14px;

    &::before {
        width: 14px;
        height: 14px;
    }
}
.centerbar .influencer-row .tiktok::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23212529' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
}
.message-box .message-title .tiktok::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23fff' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
}
.user-social-accounts li .tiktok, .advanced-filter-panel .tiktok {
    width: 16px;
    height: 16px;

    &::before {
        width: 16px;
        height: 16px;
    }
}
.user-social-accounts li .tiktok::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23fff' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
}
#networks .user-social-accounts li .tiktok::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.01 482.01'><path fill='%23212529' d='M470,120q-.56,43.11-1.12,86.23c-64.4,1.26-100.6-10.82-137.63-34.72,1.5,149.74,15.53,251-86.16,302.37-26,13.12-72.64,22.25-109.66,11.2C-24.35,437.37-11.26,178.79,178,179.34v90.71c-104.76,11.74-66.28,158.51,22.38,125.43,43.64-16.29,40.29-63.38,40.28-121Q241.16,141.29,241.73,8h89.52C340.12,85.94,381,121.2,470,120Z' transform='translate(-24.99 -8)'/></svg>");
}
.platform-wrapper .social-wrapper .tiktok {
    width: 30px;
    height: 30px;

    &::before {
        width: 30px;
        height: 30px;
    }
}
.offer-details .sub-header .social-wrapper .tiktok {
    width: 64px;
    height: 64px;

    &::before {
        width: 64px;
        height: 64px;
    }
}


.x-icon {
    display: inline-block;
    font-size: 0 !important;
    width: 24px;
    height: 24px;

    &::before {
      display: inline-block;
      content: "";
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path d='M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z'/></svg>");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &.square::before {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'><path d='M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z'/></svg>");
    }
}
.twitter a:not(.active) .x-icon::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='%23bfbfbf' d='M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z'/></svg>");
}
.offer-details .sub-header .social-wrapper .x-icon {
    width: 64px;
    height: 64px;
}
.social-sharing-badge-links .x-icon, .dashed-box .x-icon {
    width: 50px;
    height: 50px;
    vertical-align: top;
}
.dashed-box .x-icon::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='%23999' d='M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z'/></svg>");
}
.orange-box .x-icon::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='%23e67b1b' d='M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z'/></svg>");
    width: 46px;
    height: 46px;
}
.myprofile-content .social-followers .fa.x-icon {
    width: 32px;
    height: 32px;
}
.platform-wrapper .social-wrapper .x-icon {
    width: 30px;
    height: 30px;
}
.followers-container a .fa.x-icon {
    width: 28px;
    height: 28px;
    vertical-align: sub;
}
.social-account-stat li a i.x-icon, .social-account-stat li span i.x-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}
.influencer-row .x-icon, .social-wrapper .x-icon {
    width: 18px;
    height: 18px;
    vertical-align: top;
}
.user-social-accounts li .x-icon, .advanced-filter-panel .x-icon {
    width: 16px;
    height: 16px;
}
.centerbar .influencer-row .x-icon, .message-box .message-title .x-icon {
    width: 14px;
    height: 14px;
    vertical-align: middle;
}
.message-box .message-title .x-icon::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='%23fff' d='M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z'/></svg>");
}
footer .x-icon {
    width: 18px;
    height: 18px;
    vertical-align: sub;

    &::before {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='%23fff' d='M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z'/></svg>");
    }
}

@media screen and (max-width: 991.98px) {
    .dashed-box .tiktok {
        width: 29px;
        height: 29px;
            
        &::before {
            width: 29px;
            height: 29px;
        }
    }
    .dashed-box .x-icon {
        width: 29px;
        height: 29px;
    }
}
