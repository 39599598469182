// _variables.scss

$default: #292929;
$blue: #005e90;
$lightblue: #00689f;
$publicblue: #14689b;
$orange: #e47818;
$orangeHover: #be5a00;
$lightorange: #f1e7de;
$red: #a94442;
$lightred: #fbebec;
$darkred: #75121b;
$yellow: #e3c700;
$lightyellow: #fffada;
$green: #49A240;
$lightgreen: #e2ece3;
$teal: #77d2af;
$grey: #767676;
$lightgrey: #b9b9b9;
$darkgrey: #525252;
$purple: #b56cd5;
$darkbrown: #5d5a42;
