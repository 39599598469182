// _bootstrap.scss

@import 'variables';

body {
    font-family: 'Open Sans',Segoe UI,Helvetica,Helvetica Neue,Arial,sans-serif;
    font-size: 14px;
    background-color: #f3f3f4;
    overflow-x: hidden;
}

a, .page-link, .btn-link {
    color: $blue;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 200;
}

h1, .h1 {
    font-size: 30px;
}

h2, .h2 {
    font-size: 24px;
}

h3, .h3 {
    font-size: 20px;
}

h4, .h4 {
    font-size: 18px;
}

h5, .h5 {
    font-size: 14px;
}

h6, .h6 {
    font-size: 12px;
}

.btn {
    font-size: 16px;
    
    &.btn-link {
        font-size: 14px;
    }
}

.text-danger,
.invalid-feedback,
.text-red,
.icon-color.needing-action,
.icon-color.needing-action:hover {
    color: $red;
}

.text-decoration-underline {
    text-decoration: underline;
}

.form-control.is-invalid {
    border-color: $red;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23a94442' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23a94442' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}

.form-check-input.is-invalid {
    border-color: $red;
}

.table thead th {
    border-top: 0 none;
    font-weight: normal;
    
    &.num {
        text-align: right;
    }
}

.table > :not(:first-child) {
    border-top-color: $lightgrey;
}

.wrapper .table > tbody {
    border-top: 0 none;
}

.table td, .table th {
    vertical-align: middle;
}

td.num {
    text-align: right;
}

.page-item.active .page-link {
    background-color: $blue;
    border-color: $blue;
}

b, strong, .strong, dt {
    font-weight: 600;
}

.position-relative {
    z-index: 0;
}

.toast-wrapper {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 3000;
    
    .toast-success {
        color: $green;
        background-color: $lightgreen;
        
        .toast-header {
            color: $green;
            background-color: $lightgreen;
            
            b, strong {
                color: $green;
            }
        }
    }
    
    .toast-error {
        color: $red;
        background-color: $lightred;
        
        .toast-header {
            color: $red;
            background-color: $lightred;
            
            b, strong {
                color: $red;
            }
        }
    }
    
    .toast-info {
        color: var(--bs-info-text-emphasis);
        background-color: var(--bs-info-bg-subtle);
        
        .toast-header {
            color: var(--bs-info-text-emphasis);
            background-color: var(--bs-info-bg-subtle);
            
            b, strong {
                color: var(--bs-info-text-emphasis);
            }
        }
    }
}

.alert-danger > ul {
    margin-bottom: 0;
}

.form-check {
    &.check-square,
    &.check-circle,
    &.radio-circle {
        .form-check-input {
            height: 22px;
            width: 22px;
            margin-top: 0;
        }
        
        .form-check-input ~ .form-check-label {
            margin-left: 6px;
        }
        
        .form-check-input:disabled ~ .form-check-label {
            opacity: 0.5;
        }
    }
    
    &.check-teal,
    &.radio-teal {
        .form-check-input:checked,
        .form-check-input[type="checkbox"]:indeterminate {
            border-color: $teal;
            background-color: $teal;
        }
    }
    
    &.check-grey,
    &.radio-grey {
        .form-check-input:checked {
            border-color: #f0f0f0;
            background-color: #f0f0f0;
            
            &:disabled {
                background-color: #f0f0f0;
            }
        }
    }
    
    &.check-red {
        .form-check-input:checked,
        .form-check-input[type="checkbox"]:indeterminate {
            border-color: $red;
            background-color: $red;
        }
    }
    
    &.check-grey .form-check-input:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%235e5e5e' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
    
    &.radio-grey .form-check-input:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3e%3ccircle cx='50%' cy='50%' r='11' fill='%235e5e5e'/%3e%3c/svg%3e");
        background-size: 60% 60%;
    }
    
    &.radio-teal .form-check-input:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }
    
    &.check-square {
        .form-check-input[type="checkbox"] {
            border-radius: 0;
        }
    }
    
    &.check-circle,
    &.radio-circle {
        .form-check-input[type="checkbox"] {
            border-radius: 50%;
        }
    }
    
    &.check-yellow {
        .form-check-input:checked {
            border-color: $yellow;
            background-color: $yellow;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%239f901b' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
        }
        
        .form-check-input {
            height: 36px;
            width: 36px;
            margin-top: 0;
        }
    }
    
    &.form-switch.switch-green {
        padding-left: 36px;
        
        .form-check-label {
            padding-top: 8px;
            margin-left: 8px;
        }
        
        .form-check-input {
            width: 54px;
            height: 32px;
            border: #80c680 solid 1px;
            border-radius: 32px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380c680'/%3e%3c/svg%3e");
            
            &:focus {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380c680'/%3e%3c/svg%3e");
            }
            
            &:checked {
                background-color: #80c680;
                border: 1px solid #80c680;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
            }
        }
    }
}

.btn-check + label {
    color: #6c757d;
}

.btn-check:checked + label {
    color: $orange;
}
