// global.scss

@import 'icons';
@import 'bootstrap';

.text-default, .btn-link.text-default {
    color: #212529;
}

.text-orange {
    color: $orange !important;
}

a.text-orange:hover {
    color: $orangeHover !important;
}

.text-purple {
    color: $purple !important;
}

.text-blue {
    color: $blue !important;
}

.text-teal {
    color: $teal !important;
}

.text-green, .green-label {
    color: $green !important;
}

.text-yellow, .yellow-label {
    color: $yellow !important;
}

.text-lime, .lime-label {
    color: #ffff48 !important;
}

.cancelled-color {
    color: #b9b9b9;
}

.gray-bg, .bg-muted {
    background-color: #f3f3f4;
}

.btn-white, .btn-default {
    color: inherit;
    background: #fff;
    border: 1px solid #e7eaec;
    font-size: 14px;
    
    &.active, &:active {
        box-shadow: none;
        background: #f2f2f2;
    }
    
    &:hover {
        background-color: #fbfbfb;
    }
}

.btn-primary, .btn-orange {
    color: #fff;
    background-color: $orange;
    border-color: $orange;
    padding: 6px 20px;
    font-weight: 600;
    box-shadow: inset 0 -4px 0 -2px rgba(0,0,0,.1);
    border: none;
    font-size: 14px;
    
    &.btn:hover, &:focus {
        background-color: $orangeHover;
        border-color: $orangeHover;
    }
    
    &.disabled, &:disabled {
        background-color: $orange;
        border-color: $orange;
    }
    
    &:not(:disabled):not(.disabled) {
        &.active, &:active {
            background-color: $orange;
            border-color: $orange;
            
            &.focus, &:focus {
                box-shadow: 0 0 0 0.1rem rgba(38,143,255,.5);
            }
        }
    }
    
    &.btn-lg {
        padding: 12px 20px;
        font-size: 16px;
    }
}

.btn-outline-primary, .btn-outline-orange {
    background-color: #ecd9bc;
    color: #8a5410;
    border-color: $orange;
    border: none;
    font-weight: 400;
    box-shadow: inset 0 -4px 0 -2px rgba(0,0,0,.1);
    font-size: 14px;
    
    &.btn:hover {
        color: #fff;
        background-color: $orange;
        border-color: $orangeHover;
    }
    
    &:not(:disabled):not(.disabled) {
        &.active, &:active {
            background-color: $orange;
            border-color: $orange;
        }
    }
}

.btn-yellow {
    color: #5c5312;
    background-color: $yellow;
    box-shadow: inset 0 -4px 0 -2px rgba(0,0,0,.1);
    
    &.btn:hover {
        background-color: #e5cc25;
    }
}

.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.1rem rgba(38, 143, 255, 0.5);
}

.btn, .btn-primary, .btn-outline-primary {
    &.focus, &:focus {
        box-shadow: 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }
}

.btn-danger {
    font-size: 14px;
    font-weight: 600;
    padding: 6px 20px;
    box-shadow: inset 0 -4px 0 -2px rgba(0,0,0,.1);
    border: none;
}

.strength-meter {
    position: relative;
    display: block;
    height: 10px;
    width: 100%;
    background-color: #fff;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    
    &::after {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 1px;
        top: 0;
        left: 50%;
        border-left: 1px solid #ced4da;
    }
    
    #password-score {
        display: block;
        height: 9px;
        width: 4%;
        background-color: tomato;
        
        &.score-0 {
            width: 4%;
            background-color: tomato;
        }
        
        &.score-1 {
            width: 25%;
            background-color: #e3c700;
        }
        
        &.score-2,
        &.score-3,
        &.score-4 {
            background-color: #80c680;
        }
        
        &.score-2 {
            width: 50%;
        }
        &.score-3 {
            width: 75%;
        }
        &.score-4 {
            width: 100%;
        }
    }
}

.has-error .strength-meter {
    border-color: #ed5565;
    
    &::after {
        border-color: #ed5565;
    }
}
